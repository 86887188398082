import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import HappyImg from 'common/src/assets/image/saasModern/happy.svg';
import Fade from 'react-reveal/Fade';
import { CIRCULO_CUADRO_DASHBOARD_URL } from 'common/src/data/SaasModern';
import Button from 'common/src/components/Button';

const FeatureSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  row,
  col,
  FeatureItemStyle,
  iconStyle,
  contentStyle,
  featureTitle,
  featureDescription,
  buttonWrapper,
  fillButton,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FEATURES {
          title
          description
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="feature_section">
      <Container>
        <Fade bottom>
          <Box {...row}>
            <Box {...secTitleWrapper} {...col}>
              {/* <Text {...secText} content="Caracteristicas" /> */}
              <Heading
                {...secHeading}
                content="¿Por qué elegir circulo cuadro?"
              />
            </Box>
            <Box {...col}>
              <img src={HappyImg} style={{ width: 100 + '%' }} alt="happy" />
            </Box>
          </Box>
        </Fade>
        <Fade bottom>
          <Box {...row}>
            {Data.saasModernJson.FEATURES.map((item, index) => (
              <Box {...col} key={`feature-item-${index}`}>
                <FeatureBlock
                  icon={
                    <Image
                      src={item.icon.publicURL}
                      alt={`feature-item-icon-${index + 1}`}
                    />
                  }
                  wrapperStyle={FeatureItemStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  iconPosition="left"
                  title={<Heading content={item.title} {...featureTitle} />}
                  description={
                    <Text content={item.description} {...featureDescription} />
                  }
                />
              </Box>
            ))}
            <Box {...buttonWrapper}>
              <a href={CIRCULO_CUADRO_DASHBOARD_URL}>
                <Button {...fillButton} title="Pruébalo Ahora" />
              </a>
            </Box>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
  fillButton: PropTypes.object,
};

FeatureSection.defaultProps = {
  sectionWrapper: {
    pt: ['50px', '50px', '50px', '50px', '50px'],
    pb: ['20px', '50px', '60px', '70px', '100px'],
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    //fontSize: ['20px', '24px'],
    //fontWeight: '900',
    fontSize: ['24px', '32px', '38px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    //lineHeight: '1.67'
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-30px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-30px', '-45px'],
  },
  col: {
    //width: [1, 1 / 2, 1 / 2, 1 / 3],
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pl: ['30px', '30px', '30px', '30px', '45px'],
    pr: ['30px', '30px', '30px', '30px', '45px'],
    mb: ['50px', '70px'],
  },
  FeatureItemStyle: {
    alignItems: 'center',
  },
  iconStyle: {
    width: ['90px', '90px', '90px', '75px', '90px'],
    pr: '20px',
  },
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: ['10px', '15px'],
  },
  featureDescription: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    mb: 0,
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '16px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '300px'],
    height: ['40px', '56px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
    width: '100%',
  },
};

export default FeatureSection;
