import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Text from 'common/src/components/Text';
import DBMobileViewsStyle from './dbmobileviews.style';
import Fade from 'react-reveal/Fade';
import { CIRCULO_CUADRO_DASHBOARD_URL } from 'common/src/data/SaasModern';
import MessageIcon from 'common/src/assets/image/saasModern/message.svg';
import FingerprintIcon from 'common/src/assets/image/saasModern/fingerprint.svg';
import BackpackIcon from 'common/src/assets/image/saasModern/backpack.svg';
import CRMImg from 'common/src/assets/image/saasModern/1 _CRM_Y_ORGANIZADOR.png';
import PlanImg from 'common/src/assets/image/saasModern/2_PLANEACION .png';
import AutomizeImg from 'common/src/assets/image/saasModern/3_AUTOMATIZACION.png';
import CurlyArrowImg from 'common/src/assets/image/saasModern/curly_arrow.svg';

const DBMobileViews = ({
  className,
  children,
  dbMobileViewsStyle,
  title,
  description,
  fillButton,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['reusecore__navbar'];

  const [currentTab, setCurrentTab] = useState('controla');

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <DBMobileViewsStyle className={addAllClasses.join(' ')} {...props}>
      <div className="horizontal-tabs w-tabs">
        <Fade left>
          <div className="vertical-tabs-menu w-tab-menu">
            <a
              className={
                currentTab === 'controla'
                  ? 'tab-button w-inline-block w-tab-link w--current'
                  : 'tab-button w-inline-block w-tab-link'
              }
              onClick={() => setCurrentTab('controla')}
            >
              <img src={BackpackIcon} alt="" className="tab-icon" />
              <div className="text-block-23">Controla</div>
            </a>
            <a
              className={
                currentTab === 'optimiza'
                  ? 'tab-button w-inline-block w-tab-link w--current'
                  : 'tab-button w-inline-block w-tab-link'
              }
              onClick={() => setCurrentTab('optimiza')}
            >
              <img src={FingerprintIcon} alt="" className="tab-icon" />
              <div>Optimiza</div>
            </a>
            <a
              className={
                currentTab === 'automatización'
                  ? 'tab-button w-inline-block w-tab-link w--current'
                  : 'tab-button w-inline-block w-tab-link'
              }
              onClick={() => setCurrentTab('automatización')}
            >
              <img src={MessageIcon} alt="" className="tab-icon" />
              <div>Automatiza</div>
            </a>
          </div>
        </Fade>
        <Fade right>
          <div className="horizontal-tabs-content w-tab-content">
            <div
              className={
                currentTab === 'controla'
                  ? 'w-tab-pane w--tab-active'
                  : 'w-tab-pane'
              }
            >
              <div className="content-pair">
                <div className="mobile-screenshot-wrapper">
                  <img src={CRMImg} width="492" alt="" />
                </div>
                <div className="mobile-screenshot-text">
                  <img
                    src={CurlyArrowImg}
                    data-w-id="1074e097-7c87-e9bf-1306-1bbd4480b02a"
                    alt=""
                    className="screenshot-arrow"
                  />
                  <Heading {...title} content="CRM y organizador" />
                  <Text
                    {...description}
                    content="Da de alta a tus clientes, proveedores, servicios y productos para que te sea fácil administrar tu operación"
                  />
                  <a href={CIRCULO_CUADRO_DASHBOARD_URL}>
                    <Button {...fillButton} title="Pruébalo Ahora" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                currentTab === 'optimiza'
                  ? 'w-tab-pane w--tab-active'
                  : 'w-tab-pane'
              }
            >
              <div className="content-pair">
                <div className="mobile-screenshot-wrapper">
                  <img src={PlanImg} width="492" alt="" />
                </div>
                <div className="mobile-screenshot-text">
                  <img
                    src={CurlyArrowImg}
                    data-w-id="1074e097-7c87-e9bf-1306-1bbd4480b02a"
                    alt=""
                    className="screenshot-arrow"
                  />
                  <Heading {...title} content="Planeación eficiente" />
                  <Text
                    {...description}
                    content="Sorprende a tus clientes dándoles acceso a sus dashboards inteligentes, en donde podrán visualizar las actividades, archivos, pdf’s diseños, videos y audios que haz trabajado."
                  />
                  <a href={CIRCULO_CUADRO_DASHBOARD_URL}>
                    <Button {...fillButton} title="Pruébalo aAhora" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                currentTab === 'automatización'
                  ? 'w-tab-pane w--tab-active'
                  : 'w-tab-pane'
              }
            >
              <div className="content-pair">
                <div className="mobile-screenshot-wrapper">
                  <img src={AutomizeImg} width="492" alt="" />
                </div>
                <div className="mobile-screenshot-text">
                  <img
                    src={CurlyArrowImg}
                    data-w-id="1074e097-7c87-e9bf-1306-1bbd4480b02a"
                    alt=""
                    className="screenshot-arrow"
                  />
                  <Heading {...title} content="Automatiza" />
                  <Text
                    {...description}
                    content="Administra tus servicios de acuerdo a tu modelo de negocios. Cobra de manera recurrente o en una sola exhibición. Recibe pagos automáticamente y distribuye el dinero a tus empleados o proveedores, obteniendo una utilidad de las transacciones."
                  />
                  <a href={CIRCULO_CUADRO_DASHBOARD_URL}>
                    <Button {...fillButton} title="Pruébalo Ahora" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </DBMobileViewsStyle>
  );
};

DBMobileViews.propTypes = {
  /** ClassName of the Navbar. Default class is reusecore__navbar*/
  className: PropTypes.string,

  /** Used to render menu, logo, button or any component that
   * you want to show in navbar. */
  children: PropTypes.element,

  boxShadow: PropTypes.string,

  color: PropTypes.string,

  display: PropTypes.string,

  alignItems: PropTypes.string,

  justifyContent: PropTypes.string,

  flexDirection: PropTypes.string,

  flexWrap: PropTypes.string,
};

/** Navbar default proptype */
DBMobileViews.defaultProps = {
  title: {
    fontSize: ['24px', '32px', '38px'],
    fontWeight: '700',
    letterSpacing: '-0.025em',
    mb: '10px',
  },
  description: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    mb: '20px',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '16px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '300px'],
    height: ['40px', '56px'],
    minHeight: 'auto',
  },
};

export default DBMobileViews;
